import React from "react"
import { Link } from "gatsby"
import HeaderNav from "./header-nav"

const Header = () => (
  <nav id="header" className="fixed w-full z-30 top-0 text-white">
    <div className="w-full container mx-2 sm:mx-5 sm:mx-auto flex flex-wrap items-center justify-between mt-0 py-2">
      <div className="sm:pl-4 flex items-center">
        <Link to="/" className="navbar-brand logo-white">
          <img
            src="/images/snapbean-logo.png"
            width="188"
            height="50"
            alt="header-logo"
          />
        </Link>
      </div>

      <HeaderNav />
    </div>
  </nav>
)

export default Header
