import React from "react"
import { Link } from "gatsby"

const Footer = () => (
  <footer className="bg-gray-800 text-gray-100 border-b py-8">
    <div className="container mx-auto px-8">
      <div className="w-full flex flex-col md:flex-row py-6">
        <div className="flex-1">
          <p className="uppercase text-gray-400 md:mb-6">Social</p>
          <ul className="list-reset mb-6">
            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
              <a
                href="https://www.linkedin.com/company/snapbean"
                className="text-gray-100"
              >
                LinkedIn
              </a>
            </li>
          </ul>
        </div>
        <div className="flex-1">
          <p className="uppercase text-gray-500 md:mb-6">Company</p>
          <ul className="list-reset mb-6">
            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
              <Link className="text-gray-100" to="/#contact">
                Kontakt
              </Link>
            </li>
            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
              <a href="/imprint" className="text-gray-100">
                Impressum
              </a>
            </li>
            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
              <a href="mailto:info@snapbean.ch" className="text-gray-100">
                info@snapbean.ch
              </a>
            </li>
            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
              <a href="tel:+41585109760" className="text-gray-100">
                +41 58 510 97 60
              </a>
            </li>
          </ul>
        </div>
        <div className="flex-1">
          <p className="uppercase text-gray-500 md:mb-6">Insights</p>
          <ul className="list-reset mb-6">
            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
              <a href="https://dev.to/flyingdot" className="text-gray-100">
                Blog Dani
              </a>
            </li>
            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
              <a href="https://dev.to/manuelsidler" className="text-gray-100">
                Blog Manu
              </a>
            </li>
          </ul>
        </div>
        <div className="flex-1">
          <a href="https://www.swissmadesoftware.org/">
            <img
              src="/images/swissmadesoftware.png"
              alt="swiss-made-software-logo"
            />
          </a>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
