import React from "react"
import { Link } from "gatsby"

const HeaderNav = () => (
  <>
    <div
      className="w-full flex-grow lg:flex lg:items-center lg:w-auto hidden mt-2 lg:mt-0 bg-white lg:bg-transparent text-black p-4 lg:p-0 z-20"
      id="nav-content"
    >
      <ul className="list-reset lg:flex justify-end flex-1 items-center">
        <li className="mr-3">
          <Link to="/#services" className="text-gray-600 toggleColour">
            Leistungen
          </Link>
        </li>
        <li className="mr-3">
          <Link to="/#customers" className="text-gray-600 toggleColour">
            Kunden
          </Link>
        </li>
        <li className="mr-3">
          <Link to="/#solutions" className="text-gray-600 toggleColour">
            Lösungen
          </Link>
        </li>
      </ul>
    </div>
  </>
)

export default HeaderNav
